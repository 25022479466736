<template>
    <AppLayout wrapperClass="pageNotFound">
      <Breadcrumbs />
      <section class="page-not-found pt-8 pb-20">
        <b-container>
          <b-row>
            <b-col lg="12" class="not-found-img pb-30 pb-lg-0">
              <span class="h1 d-block text-center mb-30 mb-lg-45 px-30 px-md-0">{{
                $t("404_line_1")
              }}</span>
              <div class="img mb-30 mb-lg-60">
                <img src="@/base/assets/not-found.png" alt="Not Found" />
              </div>
              <span class="h3 d-block text-center font-weight-normal"
                >{{ $t("404_line_2") }}
              </span>
              <span class="h3 d-block text-center font-weight-normal">{{
                $t("404_line_3")
              }}</span>
              <Retargeted page="notfound" v-if="hrEnabled" />
            </b-col>
          </b-row>
        </b-container>
      </section>
    </AppLayout>
  </template>
  
  <script>
  import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
  import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
  const Retargeted = () => import("@/base/core/components/home/Retargeted.vue");
  import { isServer } from "@storefront/core/helpers";
  import config from "@config";
  
  export default {
    name: "PageNotFound",
    components: {
      AppLayout,
      Breadcrumbs,
      Retargeted,
    },
    computed: {},
    metaInfo() {
      return {
        meta: [
          { name: "title", content: this.$t("404_line_1") },
          { name: "keywords", content: this.$t("404_line_1") },
          { name: "description", content: this.$t("404_line_1") },
          {
            name: "robots",
            content:
              "nofollow,noindex,noarchive,nositelinkssearchbox,nosnippet,noimageindex",
          },
        ],
        title: this.$t("404_line_1"),
      };
    },
    data() {
      return {
        hrEnabled: config.helloRetail && config.helloRetail.enabled,
      };
    },
    async mounted() {
      if (!isServer && config.helloRetail && config.helloRetail.enabled) {
        var hello_retail_id = this.$cookies.get("hello_retail_id");
        await this.$store.dispatch("home/loadRetargetedProducts", {
          hello_retail_id,
          page: "notfound",
        });
      }
      const bcrumb = { current: "404", routes: [] };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
  };
  </script>
  
  